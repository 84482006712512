import { Button } from "@fluentui/react-components";
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { Link } from "react-router-dom";
import { APIStatusCheckPage } from "./APIStatusCheckPage";

export const HomePage = () => {
  const msal = useMsal();
  
  const loggOff = async () => {
    await msal.instance.logout(loginRequest);
  }

  const doLogin = () => {
    msal.instance.loginRedirect(loginRequest);
  }

  return (
    <div className="container">
      <AuthenticatedTemplate>
        <div>
            <h2>Welcome to Frontline App AI Chat Demo</h2>
            <div>Please select one of the following AI Assistant</div>
            <div><Link to="AIChat/asst_W1y1vJgELTJQeU3Jq0M3IneD"><h2 style={{color: "lightblue"}}>FDV Assistant (asst_W1y1vJgELTJQeU3Jq0M3IneD)</h2></Link></div>
            <div><Link to="AIChat/asst_r0Oac11gWzoh2QF25ANPRmmT"><h2 style={{color: "lightblue"}}>DVAM Assistant (asst_r0Oac11gWzoh2QF25ANPRmmT)</h2></Link></div>
            <APIStatusCheckPage />
        </div>
        <Button onClick={() => { loggOff() }}>Log Off</Button>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Button onClick={(e) => doLogin()}>Login</Button>
      </UnauthenticatedTemplate>
    </div>
  );
};


