import { IMsalContext } from "@azure/msal-react";
import { apiConfig, loginRequest } from "../authConfig"
import * as Models from "../Models/ChatModels";

export class AIChatApi {

    private msal: IMsalContext;

    constructor(msal: IMsalContext) {
        this.msal = msal;
    }

    async askQuestion(threadId: string, assistantId: string, message: Models.MessageInput) {
        var result = await this.msal.instance.acquireTokenSilent(loginRequest);
        var url = `${apiConfig.apiEndpoint}/api/AIChat/AskQuestion/${threadId}/${assistantId}`;
        let response = await fetch(url, {
            method: 'post',
            headers: new Headers({
                'Authorization': `bearer ${result.accessToken}`,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(message)
        });
        const data: Models.Message[] = await response.json();
        console.log(`Total ${data.length} answer return`);
        return data;
    }

    async checkStatus() {
        try {
            var result = await this.msal.instance.acquireTokenSilent(loginRequest);
            console.log(result.accessToken);
            var url = `${apiConfig.apiEndpoint}/api/AIChat/CheckStatus`;
            let response = await fetch(url, {
                method: 'get',
                headers: new Headers({
                    'Authorization': `bearer ${result.accessToken}`
                })
            });
            if (response.status == 200) {
                return true;
            }
            else {
                return false;
            }
        }
        catch (error)
        {
            debugger;
            console.log(error);
        }
        return false;
    }

    async listAssistants() {
        var result = await this.msal.instance.acquireTokenSilent(loginRequest);
        var url = `${apiConfig.apiEndpoint}/api/AIChat/ListAssistants`;
        let response = await fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': `bearer ${result.accessToken}`,
                'Content-Type': 'application/json'
            })
        });
        const data: Models.Assistant[] = await response.json();
        return data;
    }

    async getAssistant(assistantId: string) {
        var result = await this.msal.instance.acquireTokenSilent(loginRequest);
        var url = `${apiConfig.apiEndpoint}/api/AIChat/GetAssistant/${assistantId}`;
        let response = await fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': `bearer ${result.accessToken}`,
                'Content-Type': 'application/json'
            })
        });
        const data: Models.Assistant = await response.json();
        return data;
    }

    async createThread() {
        var result = await this.msal.instance.acquireTokenSilent(loginRequest);
        var url = `${apiConfig.apiEndpoint}/api/AIChat/CreateThread`;
        let response = await fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': `bearer ${result.accessToken}`,
                'Content-Type': 'application/json'
            })
        });
        var json = await response.json();
        console.log(`createThread: ${JSON.stringify(json)}`);
        const data: Models.ChatThread = json;
        return data;
    }

    async deleteThread(threadId: string) {
        var result = await this.msal.instance.acquireTokenSilent(loginRequest);
        var url = `${apiConfig.apiEndpoint}/api/AIChat/DeleteThread/${threadId}`;
        let response = await fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': `bearer ${result.accessToken}`,
                'Content-Type': 'application/json'
            })
        });
        const data: Models.ChatThread = await response.json();
        return data;
    }

    async sendMessage(threadId: string, message: Models.MessageInput) {
        var result = await this.msal.instance.acquireTokenSilent(loginRequest);
        var url = `${apiConfig.apiEndpoint}/api/AIChat/SendMessage/${threadId}`;
        let response = await fetch(url, {
            method: 'post',
            headers: new Headers({
                'Authorization': `bearer ${result.accessToken}`,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(message)
        });
        const data: Models.ChatThread = await response.json();
        return data;
    }

    async createThreadRun(threadId: string, run: Models.ThreadRunInput) {
        var result = await this.msal.instance.acquireTokenSilent(loginRequest);
        var url = `${apiConfig.apiEndpoint}/api/AIChat/CreateThreadRun/${threadId}`;
        let response = await fetch(url, {
            method: 'post',
            headers: new Headers({
                'Authorization': `bearer ${result.accessToken}`,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(run)
        });
        const data: Models.ThreadRun = await response.json();
        return data;
    }

    async getThreadRun(threadId: string, runId: string) {
        var result = await this.msal.instance.acquireTokenSilent(loginRequest);
        var url = `${apiConfig.apiEndpoint}/api/AIChat/GetThreadRun/${threadId}/${runId}`;
        let response = await fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': `bearer ${result.accessToken}`,
                'Content-Type': 'application/json'
            })
        });
        const data: Models.ThreadRun = await response.json();
        return data;
    }

    async listThreadMessages(threadId: string) {
        var result = await this.msal.instance.acquireTokenSilent(loginRequest);
        var url = `${apiConfig.apiEndpoint}/api/AIChat/ListThreadMessages/${threadId}`;
        let response = await fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': `bearer ${result.accessToken}`,
                'Content-Type': 'application/json'
            })
        });
        const data: Models.Message[] = await response.json();
        return data;
    }
}