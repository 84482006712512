import { useMsal } from "@azure/msal-react"
import { useEffect, useState } from "react"
import { AIChatApi } from "../Services/AIChatApi";

export const APIStatusCheckPage = () => {
    const [apiStatsResult, setApiStatusResult] = useState(false);
    const msal = useMsal();
    useEffect(() => {
        const checkStatus = async () => {
            const api = new AIChatApi(msal);
            var result = await api.checkStatus();
            setApiStatusResult(result);
          }
          checkStatus();
    }, [])
    if (apiStatsResult) {
        return (
            <div><h2>API is online</h2></div>
        )
    }
    return (
        <div><h2>API is offline</h2></div>
    )
}