import { useMemo, useEffect, useState } from 'react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { AiChat } from '@nlux/react';
import '@nlux/themes/nova.css';
import '../custom-nova-theme.css';
import { user, botStyle } from './personas';
import { myCustomPromiseAdapter } from '../Services/OpenAIAdaptor'
import { useParams } from 'react-router-dom';
import { AIChatApi } from '../Services/AIChatApi';
import { IMsalContext, useMsal } from '@azure/msal-react';
import { ChatThread } from '../Models/ChatModels';

type ChatProps = {
    pca: IMsalContext;
};
export default () => {
    const msal = useMsal();
    const api = new AIChatApi(msal);
    const [currentThread, setCurrentThread] = useState<ChatThread | undefined>(undefined);

    useEffect(() => {
        const createThread = async () => {
            var thread = await api.createThread();
            setCurrentThread(thread);
            myCustomPromiseAdapter.threadId = thread.id;
            console.log(`threadId id is ${thread.id}`)
        }
        console.log("Creating chat thread.")
        createThread();
    }, [])

    const { id } = useParams();
    console.log(`assistant id is ${id}`)
    myCustomPromiseAdapter.assisantId = id;
    myCustomPromiseAdapter.msal = msal;
    myCustomPromiseAdapter.api = new AIChatApi(msal);
    const adapter = useMemo(() => myCustomPromiseAdapter, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <AiChat
                className="custom-ai-chat-comp"
                adapter={adapter}
                personaOptions={{
                    bot: {
                        name: 'iBot',
                        picture: <span style={botStyle}>🤖</span>,
                        tagline: 'The Frontline App AI Assistant'
                    },
                    user
                }}
                layoutOptions={{
                    height: 600,
                    maxWidth: 600
                }}
            />
        </div>
    );
};
